import ScrollToBtn from '@/components/common/scrollto/ScrollToBtn.vue'

export default {
	name: 'MainPage',
	components: {ScrollToBtn},
	data() {
		return {
			loading: false
		}
	},
	methods: {
		updatePaused() {
			this.loading = false
		},
		showLoading() {
			this.loading = true
		}
	},

}
