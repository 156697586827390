import getServices from '@/api/services';

export default {
	name: 'WhatWeDo',
	data() {
		return {}
	},
	created() {
		getServices.getAll(this);
	},
	computed: {
		servicesList() {
			if (this.state.services.data) {
				return this.state.services.data
			}
			return []
		}
	}
}
