import getClientsLogos from '@/api/clientsLogos';

export default {
	name: 'Brands',
	data() {
		return {}
	},
	created() {
		getClientsLogos.getAll(this)
	},
	computed: {
		logosList() {
			if (this.state.clientsLogos) {
				return this.state.clientsLogos.data
			}
			return []
		}
	}
}
