import MainPage from '@/components/home/main-page/MainPage';
import WhatWeDo from '@/components/home/what-we-do/WhatWeDo';
import Brands from '@/components/home/brands/Brands.vue';
import About from "@/components/home/about/About.vue";
import LatestWork from "@/components/works/latest-work/LatestWork.vue";
import Reviews from "@/components/home/reviews/Reviews.vue";
import Form from "@/components/form/Form.vue";

export default {
	name: 'Home',
	components: {
		MainPage,
		WhatWeDo,
		Brands,
		About,
		LatestWork,
		Reviews,
		Form
	},
	metaInfo: {
		title: 'ImmerseFX - Full Spectrum of VFX & Extended Reality Services'
	}
}
